<template>
  <div>
    <el-card class="st-card">
      <h3>菜品分类</h3>

      <!-- 搜索模块 -->
      <div class="st-search">
<!--        <el-form :inline="true" :model="searchForm">-->
<!--          <el-form-item label="申报状态：">-->
<!--            <el-select-->
<!--              v-model="searchForm.status"-->
<!--              placeholder="请选择">-->
<!--              <el-option-->
<!--                v-for="item in applyStatus"-->
<!--                :key="item.type"-->
<!--                :label="item.name"-->
<!--                :value="item.type">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="申报人手机：">-->
<!--            <el-input v-model="searchForm.mobile"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item>-->
<!--            <el-button type="primary">查询</el-button>-->
<!--            <el-button>清空</el-button>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
      </div>

      <!-- 菜品分类 -->
      <div class="catalog-table">
        <div class="table-header">
          <el-row :gutter="20">
            <el-col :span="3">序号</el-col>
            <el-col :span="5">分类名称</el-col>
            <el-col :span="4">菜品数量</el-col>
            <el-col :span="5">创建时间</el-col>
            <el-col :span="4">是否置顶</el-col>
            <el-col :span="3">操作</el-col>
          </el-row>
        </div>
        <draggable
          v-model="catalogs"
          @change="itemChange"
          class="table-body">
          <transition-group>
            <div
              v-for="item in catalogs"
              :key="item._id"
              class="catalog-item">
              <el-row :gutter="20">
                <el-col :span="3">{{ item.serial }}</el-col>
                <el-col :span="5">{{ item.name }}</el-col>
                <el-col :span="4">{{ item.number | numberFil }}</el-col>
                <el-col :span="5">{{ item.dateCreated | dateFil }}</el-col>
                <el-col :span="4">
                  <el-switch
                    disabled
                    v-model="item.isTop"
                    active-color="#e0ba8a"
                    :active-value="1"
                    :inactive-value="0">
                  </el-switch>
                </el-col>
                <el-col :span="3">
                  <el-button
                    type="primary"
                    size="small"
                    @click="clickUpdateItem(item)">
                    编辑
                  </el-button>
                </el-col>
              </el-row>
            </div>
          </transition-group>
        </draggable>
      </div>

    </el-card>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: '',
  components: {
    draggable
  },
  data () {
    return {
      applyStatus: [],
      searchForm: {},

      catalogs: []
    }
  },
  filters: {
    numberFil (val) {
      if (val >= 0) return val
      return 0
    },
    dateFil (val) {
      if (val) return moment(val).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  mounted () {
    this.getCatalogs('5ea106053d39d376f6a3c8b3')
  },
  methods: {
    // 获取菜品分类列表
    getCatalogs (bid) {
      this.$api.recipes.catalog({
        client_id: this.$config.CLIENT_IDS.ONECODE_ADMIN,
        bid: bid
      }).then(res => {
        if (res.data.success) {
          this.catalogs = _.orderBy(res.data.result, ['isTop', 'serial'], ['desc', 'asc'])
        }
      })
    },

    // 点击编辑类别项
    clickUpdateItem (item) {
      console.log(item)
    },

    itemChange (evt) {
      console.log(evt)
    }
  }
}
</script>

<style lang="sass" scoped>
  @import "./index"
</style>
